import 'url-polyfill';
import {useEffect} from 'react';
import {useRouter} from 'next/router';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import 'antd/dist/antd.css';
import '../styles/globals.sass';
import Head from 'next/head';
import {init} from '../components/sentry';

init();

function useRouterScroll({behavior = 'smooth', left = 0, top = 0} = {}) {
  const router = useRouter();
  useEffect(() => {
    // Scroll to given coordinates when router finishes navigating
    // This fixes an inconsistent behaviour between `<Link/>` and `next/router`
    // See https://github.com/vercel/next.js/issues/3249
    const handleRouteChangeComplete = () => {
      // @ts-ignore
      window.scrollTo({top, left, behavior});
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe from the event
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [behavior, left, top]);
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00B3FF',
      contrastText: '#fff',
    },
  },
});

function MyApp({Component, pageProps, err}) {
  useRouterScroll();
  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>ILO Quote</title>
      </Head>
      <Component {...pageProps} err={err} />
    </ThemeProvider>
  );
}

export default MyApp;
